import { createSelector, OutputParametricSelector } from '@reduxjs/toolkit';
import { AbstractDataState, InitialState } from './AbstractDataState';
import { AbstractContractBasedDataState } from './contractBasedDataFetchSlice';

export type ContractBasedDataSelector<TState, TBusinessObject, TAppErrorCode extends string> = OutputParametricSelector<
    TState,
    string,
    AbstractDataState<TBusinessObject, TAppErrorCode>,
    (
        res1: AbstractContractBasedDataState<TBusinessObject, TAppErrorCode>,
        res2: string,
    ) => AbstractDataState<TBusinessObject, TAppErrorCode>
>;

export function getContractBasedDataSelector<TState, TBusinessObject, TAppErrorCode extends string>(
    existingDataSelector: (state: TState) => AbstractContractBasedDataState<TBusinessObject, TAppErrorCode>,
): ContractBasedDataSelector<TState, TBusinessObject, TAppErrorCode> {
    // Based on https://react-redux.js.org/api/hooks#useselector-examples
    return createSelector(
        existingDataSelector,
        (_: TState, contractId: string) => contractId,
        (existingContractsData, contractId) => existingContractsData?.[contractId] ?? InitialState,
    );
}
