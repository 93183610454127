import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosRequestConfig } from 'axios';
import { AbstractDataState } from './AbstractDataState';
import { SimpleDataSelector } from './createSimpleDataSelector';
import { shouldRefetch } from './refetchStrategy';

export function useGetSimpleApiData<TAppThunk, TState, TBusinessObject, TAppErrorCode extends string>(
    fetchData: (requestConfig?: AxiosRequestConfig) => TAppThunk,
    existingDataSelector: SimpleDataSelector<TState, TBusinessObject, TAppErrorCode>,
    alwaysReFetch = false,
    requestConfig?: AxiosRequestConfig,
    queryString?: string,
    isFetchingAllowed?: boolean,
): AbstractDataState<TBusinessObject, TAppErrorCode> {
    const dispatch = useDispatch();
    const existingData = useSelector(existingDataSelector);

    const triggerReFetch = isFetchingAllowed !== false && (alwaysReFetch || shouldRefetch(existingData));

    useEffect(() => {
        if (triggerReFetch) {
            dispatch(fetchData(requestConfig));
        }
    }, [dispatch, fetchData, triggerReFetch, queryString]);

    return existingData;
}
