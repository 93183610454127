import { useDispatch, useSelector } from 'react-redux';
import { ContractBasedDataSelector } from './createContractBasedDataSelector';
import { AbstractDataState } from './AbstractDataState';
import { useMemoizedSelector } from './useMemoizedSelector';

export type ContractBasedUpdateDataType<
    TAppThunk,
    TBusinessObject,
    TAppErrorCode extends string,
    TObject = TBusinessObject
> = {
    updateData: (data: TObject) => TAppThunk;
    dataState: AbstractDataState<TBusinessObject, TAppErrorCode>;
};

/**
 * Updates data property of contract with given ID
 */
export function useGetContractBasedUpdateData<TAppThunk, TBusinessObject, TState, TAppErrorCode extends string>(
    contractId: string,
    updateData: (contractId: string, data: TBusinessObject) => TAppThunk,
    existingDataSelector: ContractBasedDataSelector<TState, TBusinessObject, TAppErrorCode>,
): ContractBasedUpdateDataType<TAppThunk, TBusinessObject, TAppErrorCode> {
    const dispatch = useDispatch();
    const memoizedExistingDataSelector = useMemoizedSelector(existingDataSelector);
    const dataState = useSelector(state => memoizedExistingDataSelector(state as TState, contractId));

    return {
        updateData: (data: TBusinessObject): TAppThunk => dispatch(updateData(contractId, data)),
        dataState,
    };
}
