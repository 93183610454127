import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

export function useCmsContent<TAppThunk, TState, TSelected>(
    fetchContent: (locale: string) => TAppThunk,
    selector: (state: TState) => TSelected,
): TSelected {
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    useEffect(() => {
        dispatch(fetchContent(i18n.languages[0]));
    }, [dispatch, i18n.languages, fetchContent]);
    return useSelector(selector);
}
