import { DataState } from './AbstractDataState';

export const REFETCH_ATTEMPTS_ON_FAILURE = 3;

export function shouldRefetch<TAppErrorCode extends string>({
    isLoading,
    loadingError,
    failedLoadingAttempts,
    hasReceivedResponse,
}: DataState<TAppErrorCode>): boolean {
    return (
        !isLoading &&
        // 1. Trigger re-fetch if last fetch was erroneous and one is allowed to retry
        ((loadingError &&
            // Type conversion will become unnecessary with https://digitalunit-fs.atlassian.net/browse/CO-6746
            loadingError.code === (('API_NOT_REACHABLE' as unknown) as TAppErrorCode) &&
            failedLoadingAttempts < REFETCH_ATTEMPTS_ON_FAILURE) ||
            // 2. Trigger re-fetch if no data response arrived and no error happened during the fetch
            // This prevents empty responses from potential re-fetch triggers
            (!loadingError && !hasReceivedResponse))
    );
}
