import moment, { Moment, MomentFormatSpecification } from 'moment-timezone';
import { CPDate } from '../types';
import { DurationInputArg1, DurationInputArg2, MomentInput, unitOfTime } from 'moment';

export const ISO_DATE_FORMAT = moment.ISO_8601;

export interface CPDateInterface {
    format: (format: string) => string;
    toCpDate: () => CPDate;
    toMoment: () => Moment;
    unix: () => number;
    diff: (b: CPDate | MomentInput, unitOfTime?: unitOfTime.Diff, precise?: boolean) => number;
    add: (amount?: DurationInputArg1, unit?: DurationInputArg2) => CPDateInterface;
    subtract: (inp: DurationInputArg1, unit: DurationInputArg2) => CPDateInterface;
    isValid: () => boolean;
}

export const formatCpDate = (
    inputDate?: string | CPDate | Moment | null,
    inputDateFormat?: MomentFormatSpecification,
    strict?: boolean,
): CPDateInterface => {
    const parsedDate: Moment =
        inputDate === undefined || inputDate === null || inputDate === ''
            ? moment()
            : moment.isMoment(inputDate)
            ? inputDate
            : moment(inputDate, inputDateFormat ?? ISO_DATE_FORMAT, strict ?? true);
    return {
        add: (amount?: moment.DurationInputArg1, unit?: moment.DurationInputArg2): CPDateInterface =>
            formatCpDate(parsedDate.add(amount, unit)),
        toCpDate: (): string => parsedDate.toISOString(),
        toMoment: (): Moment => parsedDate,
        subtract: (inp: DurationInputArg1, unit: DurationInputArg2): CPDateInterface =>
            formatCpDate(parsedDate.subtract(inp, unit)),
        diff: (b: CPDate | MomentInput, unitOfTime?: unitOfTime.Diff, precise?: boolean): number =>
            parsedDate.diff(b, unitOfTime, precise),
        format: (format: string): string => parsedDate.format(format),
        unix: (): number => parsedDate.unix(),
        isValid: (): boolean => parsedDate.isValid(),
    };
};
