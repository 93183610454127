import { Error } from './Error';

/**
 * Use for cases when the request to CP APIs times out
 */
export type NotReachableCpIntegrationErrorCode = 'CP_API_NOT_REACHABLE';
export type WithNotReachableCpIntegrationError<TAppErrorCode extends string> =
    | TAppErrorCode
    | NotReachableCpIntegrationErrorCode;

/**
 * Use for cases when the error response of a CP API does not match the Error type response structure
 */
export type UnexpectedResponseStructureCpIntegrationErrorCode = 'CP_API_UNEXPECTED_RESPONSE_STRUCTURE';
export type WithUnexpectedResponseCpIntegrationError<TAppErrorCode extends string> =
    | TAppErrorCode
    | UnexpectedResponseStructureCpIntegrationErrorCode;

/**
 * Use to combine all CP API consumer-specific errors with the pre-defined API business errors
 */
export type WithDefaultCpIntegrationErrors<TAppErrorCode extends string> = WithUnexpectedResponseCpIntegrationError<
    WithNotReachableCpIntegrationError<TAppErrorCode>
>;

/**
 * Use for cases when the request to CP APIs times out (e.g. network timeout)
 */
export const NotReachableCpIntegrationError: Error<NotReachableCpIntegrationErrorCode> = {
    status: 500,
    code: 'CP_API_NOT_REACHABLE',
    message: 'Cannot reach the integration services',
};

/**
 * Use for cases when the error response of a CP API does not match the Error type response structure. (e.g. Tomcat 404 page received)
 */
export const UnexpectedResponseStructureCpIntegrationError: Error<UnexpectedResponseStructureCpIntegrationErrorCode> = {
    status: 500,
    code: 'CP_API_UNEXPECTED_RESPONSE_STRUCTURE',
    message: 'Integration services respond with an error but no body',
};
