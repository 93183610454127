import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosRequestConfig } from 'axios';
import { AbstractDataState } from './AbstractDataState';
import { ContractBasedDataSelector } from './createContractBasedDataSelector';
import { shouldRefetch } from './refetchStrategy';
import { useMemoizedSelector } from './useMemoizedSelector';

export function useGetContractBasedApiData<TState, TAppThunk, TBusinessObject, TAppErrorCode extends string>(
    contractId: string,
    fetchData: (contractId: string, link?: string, requestConfig?: AxiosRequestConfig) => TAppThunk,
    existingDataSelector: ContractBasedDataSelector<TState, TBusinessObject, TAppErrorCode>,
    link?: string,
    alwaysReFetch = false,
    requestConfig?: AxiosRequestConfig,
): AbstractDataState<TBusinessObject, TAppErrorCode> {
    const dispatch = useDispatch();
    const memoizedExistingDataSelector = useMemoizedSelector(existingDataSelector);
    const existingData = useSelector(state => memoizedExistingDataSelector(state as TState, contractId));
    const triggerReFetch = alwaysReFetch || shouldRefetch(existingData);

    useEffect(() => {
        if (triggerReFetch) {
            dispatch(fetchData(contractId, link, requestConfig));
        }
    }, [dispatch, fetchData, contractId, link, triggerReFetch]);

    return existingData;
}
