import { Error, WithDefaultCpIntegrationErrors } from '@cp-shared-8/common-utilities';

export type DataState<TAppErrorCode extends string> = {
    isLoading: boolean;
    hasReceivedResponse: boolean;
    failedLoadingAttempts: number;
    loadingError?: Error<WithDefaultCpIntegrationErrors<TAppErrorCode>>;
};

export type AbstractDataState<TBusinessObject, TAppErrorCode extends string> = DataState<TAppErrorCode> & {
    data?: TBusinessObject;
};

export const InitialState: DataState<string> = {
    isLoading: false,
    hasReceivedResponse: false,
    failedLoadingAttempts: 0,
};

export function hasFetchedSuccessfully<TAppErrorCode extends string>(dataState: DataState<TAppErrorCode>): boolean {
    // Trivial check for data is not sufficient as it might be falsy (e.g. 0 or empty string)
    // Trivial check for hasReceivedResponse is not sufficient as it might be an error response
    return !dataState.isLoading && !dataState.loadingError && dataState.hasReceivedResponse;
}
