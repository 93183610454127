import { createSelector, OutputSelector } from '@reduxjs/toolkit';
import { AbstractDataState } from './AbstractDataState';

export type SimpleDataSelector<TState, TBusinessObject, TAppErrorCode extends string> = OutputSelector<
    TState,
    AbstractDataState<TBusinessObject, TAppErrorCode>,
    (res: AbstractDataState<TBusinessObject, TAppErrorCode>) => AbstractDataState<TBusinessObject, TAppErrorCode>
>;

export function getSimpleDataSelector<TState, TBusinessObject, TAppErrorCode extends string>(
    existingDataSelector: (state: TState) => AbstractDataState<TBusinessObject, TAppErrorCode>,
): SimpleDataSelector<TState, TBusinessObject, TAppErrorCode> {
    return createSelector(existingDataSelector, existingData => existingData);
}
