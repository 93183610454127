import {
    registerDecorator,
    ValidationOptions,
    ValidatorConstraint,
    ValidatorConstraintInterface,
} from 'class-validator';
import moment from 'moment-timezone';

@ValidatorConstraint({ name: 'isValidMoment' })
export class IsValidMomentConstraint implements ValidatorConstraintInterface {
    defaultMessage(): string {
        return 'Not a valid Moment.';
    }

    validate(value: unknown): boolean {
        return moment.isMoment(value) && value.isValid();
    }
}

export function IsValidMoment(validationOptions?: ValidationOptions): PropertyDecorator {
    return function(object: Record<string, unknown>, propertyName: string): void {
        registerDecorator({
            target: object.constructor,
            propertyName: propertyName,
            options: validationOptions,
            validator: IsValidMomentConstraint,
        });
    };
}
