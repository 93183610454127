// Heavily inspired by https://dzone.com/articles/rest-api-error-handling-problem-details-response
export type Error<TAppErrorCode extends string> = {
    // Repeats the origin HTTP status code to prevent reverse proxies setting another one
    status: number;
    // App-internal error code for type-safe handlers
    code: TAppErrorCode;
    // Human readable error explanation like "Spanish Backend Not Available"
    message: string;
    //Market url which invoked given error
    marketUrl?: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isError<TAppErrorCode extends string>(error: any): error is Error<TAppErrorCode> {
    return error.status !== undefined && error.code !== undefined && error.message !== undefined;
}
